<template>
    <div class="myclass-main">
        <div class="myclass-nav">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="stu-class-title">
                <el-breadcrumb-item>我的班级：{{className}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="myclass-content">
            <div class="myclass-left-bar">
                <div class="class-stu-list">
                    <div class="class-list-header">
                        <span>班级成员</span>
                    </div>
                    <el-scrollbar style="height: 100%;" :native="false" class="stu-list">
                        <ul class="class-stus">
                            <li v-for="(stu,index) in stuList" :key="stu.s_name+ '_' + index" @click="viewStudentDetail(stu)">
                                <div class="stu-info">
                                    <div class="stu-avatar">
                                        <img :src="stu.s_ico ? stu.s_ico : require('../../assets/images/user_img.png')" alt="">
                                    </div>
                                    <div class="stu-name-item">
                                        <span class="stu-name">{{stu.s_name}}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </el-scrollbar>
                </div>
            </div>
            <div class="myclass-right-main">
                <div class="myclass-r-header">
                    <div class="myclass-r-header-c">
                        <div class="r-header-c-tit"><h4>{{className}}</h4></div>
                    </div>
                </div>
              <div class="myclass-r-header" v-if="teacherData.course_id">
                    <div class="myclass-r-header-c" style="padding: 10px 20px;">
                        <div class="r-header-c-tit">
                            <span>
                                {{teacherData.t_name}}正在上
                                <span style="color: #409EFF;">{{teacherData.course_name}}</span>
                                ，快去签到学习吧！
                            </span>
                        </div>
                        <div class="r-header-c-act">
                            <el-button type="primary" @click="signInNow">签到上课</el-button>
                        </div>
                    </div>
                </div>
                <div class="myclass-r-content">
                    <div class="myclass-tabs">
                        <router-link :to="{path: '/teacherClass/practicalList', query: {classId: classId, className: className}}" class="tab-item practice-item">
                            <div class="tabs-item-contents">
                                <div class="icon-part practice-icon">
                                </div>
                                <div class="txt-part">
                                    <p>实训次数</p>
                                    <p class="num-total">{{shixun_num}}次</p>
                                </div>
                            </div>
                        </router-link>
                        <div class="tab-item test-item" @click="toQuizList">
                            <div class="tabs-item-contents">
                                <div class="icon-part test-icon">
                                </div>
                                <div class="txt-part">
                                    <p>小测次数</p>
                                    <p class="num-total">{{xiaoce_num}}次</p>
                                </div>
                            </div>
                        </div>
                        <!--<div class="tab-item homework-item">-->
                            <!--<div class="tabs-item-contents">-->
                                <!--<div class="icon-part homework-icon">-->
                                <!--</div>-->
                                <!--<div class="txt-part">-->
                                    <!--<p>作业次数</p>-->
                                    <!--<p class="num-total">100次</p>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</div>-->
                        <div class="tab-item discuss-item" @click="toDiscussList">
                            <div class="tabs-item-contents">
                                <div class="icon-part">
                                </div>
                                <div class="txt-part">
                                    <p>讨论次数</p>
                                    <p class="num-total">{{taolun_num}}次</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="myclass-courses">
                        <div class="course-name">
                            <h4 class="modules-title">课程内容</h4>
                        </div>
                        <div class="course-swiper-wrapper">
                            <div v-swiper:mySwiper="courseSwiperOption" class="course-swiper">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="(item, index) in myCourses" :key="index">
                                        <div class="course-cover">
                                            <img :src="item.course_cover">
                                        </div>
                                        <div class="course-content">
                                            <p class="text-overflow">{{item.course_name}}</p>
                                            <p class="text-overflow" v-if="item.is_end === 0 && item.status === 2">未开始</p>
                                            <p class="text-overflow" v-else-if="item.is_end === 0 && item.status === 0">学习进度{{item.chapter_sign + 1}}/{{item.chapter_num}}章</p>
                                            <p class="text-overflow" v-else-if="item.is_end === 1">已结束</p>
                                        </div>
                                    </div>
                                </div>
                                <!--                                <div class="swiper-pagination"></div>-->
                            </div>
                            <div class="banner-swiper-btn-prev">
                                <img src="@/assets/icons/left-icon.png" alt="">
                            </div>
                            <div class="banner-swiper-btn-next">
                                <img src="@/assets/icons/right-icon.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="myclass-r-history">
                    <div class="history-tit">
                        <h4 class="modules-title">历史记录</h4>
                    </div>
                    <el-table :data="signInData" style="width: 100%; flex: 1;" height="1%" :header-cell-style="{background: '#F5F5F5'}">
                        <el-table-column prop="tran_start_time" label="日期"></el-table-column>
                        <el-table-column prop="course_name" label="课程"></el-table-column>
                        <el-table-column prop="chapter_num" label="章节">
                            <template slot-scope="scope">
                                <span>{{scope.row.chapter_sign + 1}} / {{scope.row.chapter_num}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="签到状态">
                            <template slot-scope="scope">
                                <span v-if="scope.row.is_sign === 1">已签到</span>
                                <span v-else class="noSignIn">未签到</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="t_name" label="授课老师"></el-table-column>
                    </el-table>
                    <!--<div class="history-content">-->
                        <!---->
                    <!--</div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import Swiper from 'swiper';
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import wsc from "../../utils/wsConfig";
    import {sendWsData} from "@/utils/utils";

    export default {
        name: "Myclass",
        data() {
            return {
                classId: Number(localStorage.getItem('c_id')) || '',
                className: '',
                stuList: [],
                shixun_num: '',
                xiaoce_num: '',
                taolun_num: '',
                myCourses: [],
                signInData: [],
                chapter_sign: 0,
                teacherData: {
                    course_id: 0,
                    course_name: '',
                    t_name: ''
                },
                courseSwiperOption: {
                    autoplay: false,
                    slidesPerView : 4,
                    spaceBetween : 16,
                    // loop: true,
                    navigation: {
                        nextEl: '.banner-swiper-btn-next',
                        prevEl: '.banner-swiper-btn-prev',
                    },
                    observer: true,
                    observeParents: true,
                },
                course_id: '',
                course_record_id: '',
                t_course_id: '',
                ws: null
            }
        },
        components: {
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        created() {
            this.getStudentList();
            this.getStatistics();
            this.getCourseHistoryList();
            this.getTeacherCourseByClass();
            this.getCourseProgressList();
        },
        mounted() {
            this.wsInit()
        },
        methods: {
            wsInit() {
                this.ws = new WebSocket(wsc.url);
                this.ws.onopen = () => {
                    console.log("连接成功")
                    this.ws.send(sendWsData('bind'))
                }
                this.ws.onmessage = (evt) => {
                    const data = evt.data
                    try {
                        const result = JSON.parse(data)
                        console.log("result-student", result)
                        //教师上课的通知
                        if (result.code === 200 && result.data.type === 'notice') {
                            this.teacherData.course_name = result.data.course_name
                            this.teacherData.t_name = result.data.t_name
                            this.teacherData.course_id = result.data.course_id
                            this.course_record_id = result.data.course_record_id
                            this.t_course_id = result.data.course_id
                          this.getTeacherCourseByClass()
                        }
                        if (result.code === 204) {
                            this.$router.push("/");
                        }
                        //教师结束上课的通知
                        if (result.code === 200 && result.data.type === "end_notice") {
                            this.getTeacherCourseByClass()
                        }
                    } catch (e) {
                        console.log("返回数据格式异常：", e)
                    }
                }

                this.ws.onclose = () => {
                    console.log("断开连接")
                    this.wsInit()
                }
            },
            //绑定客户端
            bindClient(client_id) {
                this.$shttp.axiosGetBy(this.$api.bindClient, {clientID: client_id}, (res) => {
                    console.log(res)
                }, (err) => {
                    console.log(err)
                })
            },
            signInNow() {
                console.log("teacher_data", this.teacherData)
                this.$store.dispatch('setRefreshVal', 0);
                this.$shttp.axiosPost(this.$api.stuSignIn, {course_record_id: this.course_record_id}, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '签到成功！',
                            duration: 1000,
                            onClose: () => {
                                this.$router.push({
                                    path: '/course/inclass',
                                    query: {
                                        id: this.t_course_id,
                                        className: this.className,
                                        classId: Number(localStorage.getItem('c_id')),
                                        recordId: this.course_record_id,
                                        tCourseId: this.t_course_id,
                                        chapter: this.chapter_sign,
                                        node: 0,
                                    }
                                });
                                // let routeUrl = this.$router.resolve({
                                //     path: '/course/courseIndex',
                                //     query: {
                                //         id: this.teacherData.student_course_id,
                                //         className: this.className,
                                //         classId: Number(localStorage.getItem('c_id')),
                                //         recordId: this.course_record_id,
                                //         tCourseId: this.t_course_id,
                                //     }
                                // });
                                // window.open(routeUrl .href, '_blank');
                            }
                        });
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                this.$router.push({
                                    path: '/course/inclass',
                                    query: {
                                        id: this.teacherData.student_course_id,
                                        className: this.className,
                                        classId: Number(localStorage.getItem('c_id')),
                                        recordId: this.course_record_id,
                                        tCourseId: this.t_course_id,
                                        chapter: this.chapter_sign,
                                        node: 0,
                                    }
                                });
                                // let routeUrl = this.$router.resolve({
                                //     path: '/course/courseIndex',
                                //     query: {
                                //         id: this.teacherData.student_course_id,
                                //         className: this.className,
                                //         classId: Number(localStorage.getItem('c_id')),
                                //         recordId: this.course_record_id,
                                //         tCourseId: this.t_course_id,
                                //     }
                                // });
                                // window.open(routeUrl .href, '_blank');
                            }
                        });
                    }
                })
                // return
            },
            getStudentList() {
                this.$shttp.axiosGetBy(this.$api.showStudent, {type: 'all', c_id: Number(localStorage.getItem('c_id'))},(res) => {
                    if (res.code ===200) {
                        this.stuList = res.data;
                        this.className = res.data[0].c_name;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getStatistics() {
                let params = {
                    c_id: Number(localStorage.getItem('c_id'))
                }
                this.$shttp.axiosGetBy(this.$api.statistics, params,  (res) => {
                    if (res.code ===200) {
                        this.shixun_num = res.data.sx_num;
                        this.xiaoce_num = res.data.xc_num;
                        this.taolun_num = res.data.tl_num;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //学生查询教师在某个班级下的开课情况
            getTeacherCourseByClass() {
                this.$shttp.axiosGet(this.$api.getTeacherCourseByClass, (res) => {
                    if (res.code === 200) {
                        this.teacherData = res.data;
                        console.log("||", this.teacherData);
                        this.course_id = res.data.student_course_id;
                        this.chapter_sign = res.data.chapter_sign;
                        this.course_record_id = res.data.course_record_id;
                        this.t_course_id = res.data.course_id;
                    }
                })
            },
            getCourseProgressList() {
                let params = {
                    c_id: Number(localStorage.getItem('c_id')),
                    course_id: this.course_id
                }
                this.$shttp.axiosGetBy(this.$api.getCourseProgressList, params, (res) => {
                    if (res.code === 200) {
                        this.myCourses = res.data.data1;
                    }
                })
            },
            getCourseHistoryList() {
                let params = {
                    c_id: Number(localStorage.getItem('c_id')),
                    course_id: this.course_id
                }
                this.$shttp.axiosGetBy(this.$api.getCourseProgressList, params, (res) => {
                    if (res.code === 200) {
                        this.signInData = res.data.data2.data;
                    }
                })
            },
            toQuizList() {
                this.$router.push({
                    path: '/teacherClass/studentQuizList',
                    query: {
                        classId: Number(localStorage.getItem('c_id')),
                        className: this.className
                    }
                })
            },
            toDiscussList() {
                this.$router.push({
                    path: '/teacherClass/discussList',
                    query: {
                        classId: Number(localStorage.getItem('c_id')),
                        className: this.className
                    }
                })
            },
            goBack() {
                this.$router.go(-1)
            },
            //查看学生
            viewStudentDetail(item) {
                console.log('item',item)
                this.$router.push({
                    path: '/teacherClass/student/detail',
                    query: {
                        sId: item.s_id
                    }
                })
            }
        }

    }
</script>

<style lang="scss" scoped>
    .noSignIn {
        color: #FF0000;
    }
    .signInDialog {
        .el-dialog__header {border-bottom: 1px solid #f5f5f5;}
    }
    .signInHistory {
        .signInBtn {
            color: #FF0000;
            font-size: 14px;
        }
    }
    .modules-title {
        font-size: 18px;
        color: #333333;
    }
    .myclass-main {
        box-sizing: border-box;
        /*height: inherit;*/
        width: 1200px;
        margin: 0 auto;
        padding-top: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .myclass-content {
            /*height: 100%;*/
            height: 1%;
            flex: 1;
            margin: 20px 0;
            display: flex;
            .myclass-left-bar {
                box-sizing: border-box;
                height: 100%;
                background-color: #FFFFFF;
                width: 300px;
                border-radius: 8px;
                box-shadow: 2px 2px 10px rgba(32, 32, 32, 0.05);
                padding: 0 0 16px 16px;
                ul, li {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                .class-stu-list {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    .class-list-header {
                        span {
                            display: inline-block;
                            margin-top: 16px;
                            color: rgba(51, 51, 51, 1);
                        }
                    }
                    .stu-list {
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        .class-stus {
                            flex: 1;
                            li {
                                margin-top: 20px
                            }
                            .stu-info {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                .stu-avatar {
                                    width: 20px;
                                    height: 20px;
                                    overflow: hidden;
                                    border-radius: 50%;
                                    margin-right: 9px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
                                .stu-name-item {
                                    font-size: 14px;
                                    color: #333333;
                                }
                            }
                        }
                    }
                }
            }
            .myclass-right-main {
                box-sizing: border-box;
                margin-left: 14px;
                /*flex: 1;*/
                /*width: 886px;*/
                width: 1%;
                flex: 1;
                height: 100%;
                border-radius: 8px;
                background-color: #FFF;
                box-shadow: 2px 2px 10px rgba(32, 32, 32, 0.05);
                display: flex;
                flex-direction: column;
                .myclass-r-header {
                    width: 100%;
                    .myclass-r-header-c {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;
                        box-sizing: border-box;
                        padding: 0 20px;
                        margin: 0 5px;
                        border-bottom: 1px solid #EAEAEA;
                        .r-header-c-tit {
                            h4 {
                                margin: 16px 0;
                                font-size: 18px;
                                color: #333333;
                            }
                        }
                    }
                }
                .myclass-r-content {
                    /*flex: 1;*/
                    .myclass-tabs {
                        margin-top: 24px;
                        box-sizing: border-box;
                        flex: 1;
                        height: 120px;
                        padding: 0 20px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .tab-item {
                            height: 100%;
                            flex: 1;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            border-radius: 8px;
                            margin-right: 13px;
                            cursor: pointer;
                            &:last-child {
                                margin-right: 0;
                            }
                            .tabs-item-contents {
                                display: flex;
                                flex: 1;
                                justify-content: flex-start;
                                align-items: flex-start;
                                .txt-part {
                                    p {
                                        padding: 0;
                                        margin: 0;
                                        color: #FFF;
                                        font-size: 16px;
                                        &.num-total {
                                            font-size: 20px;
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                            &.practice-item {
                                background-image: linear-gradient(to right, #6BC0FF, #3A8CF6);
                                .icon-part {
                                    background-position: 0px -40px;
                                }
                            }
                            &.test-item {
                                background-image: linear-gradient(to right, #FFC443, #FF8A28);
                                .icon-part {
                                    background-position: -62px -40px;
                                }
                            }
                            &.homework-item {
                                background-image: linear-gradient(to right, #FF6B7D, #FF4C55);
                                .icon-part {
                                    background-position: -124px -40px;
                                }
                            }
                            &.discuss-item {
                                background-image: linear-gradient(to right, #47EAD1, #19C59F);
                                .icon-part {
                                    background-position: -185px -40px;
                                }
                            }
                            .icon-part {
                                width: 50px;
                                height: 50px;
                                border-radius: 10px;
                                margin: 0 24px;
                                background: url("../../assets/icons/sprite.png");
                            }
                        }
                    }
                    .myclass-courses {
                        .course-name {
                            padding-left: 25px;
                        }
                        .course-list {
                            margin: 0 18px;
                            height: 104px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            .swiper-content {
                                box-sizing: border-box;
                                margin-left: 16px;
                                height: 100%;
                                flex: 1;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                .course-panel-item {
                                    cursor: pointer;
                                    position: relative;
                                    height: 100%;
                                    flex: 1;
                                    border-radius: 8px;
                                    margin-right: 16px;
                                    .panel-modal, .txt-modal {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 100%;
                                        width: 100%;
                                        border-radius: 8px;
                                    }
                                    .panel-modal {
                                        background: rgba(0, 0, 0, 0.4);
                                    }
                                    .txt-modal {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        p {
                                            text-align: center;
                                            width: calc(100% - 20px);
                                            font-size: 16px;
                                            margin: 10px;
                                            color: #FFF;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                            }
                            .pre-click-btn, .next-click-btn {
                                width: 24px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                a {
                                    display: block;
                                    text-align: center;
                                    line-height: 24px;
                                    width: 24px;
                                    height: 24px;
                                    border-radius: 12px;
                                    background-color: #FF0000;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }
                .myclass-r-history {
                    height: 1%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .history-tit {
                        padding-left: 25px;
                    }
                    .history-content {
                        width: 100%;
                    }
                }
            }

        }
    }

    .course-swiper-wrapper {
        position: relative;
        .course-swiper {
            margin: 0 42px;
            .swiper-slide {
                width: 100%;
                position: relative;
                border-radius: 8px;
                overflow: hidden;
                .course-cover {
                    width: 100%;
                    height: 0;
                    padding-bottom: 56.1798%;
                    position: relative;
                    background: #fdf6ef;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                        transform: translate(-50%, -50%);
                    }
                }
                .course-content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, .4);
                    /*display: flex;*/
                    /*align-items: center;*/
                    /*flex-direction: column;*/
                    /*justify-content: center;*/
                    p {
                        color: #fff;
                        font-size: 18px;
                        margin: 20px 0 0;
                        padding: 0 10px;
                        text-align: center;
                    }
                }
            }
        }
        .banner-swiper-btn-prev,
        .banner-swiper-btn-next {
            position: absolute;
            top: 50%;
            bottom: 0;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #FF0000;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            /*opacity: .8;*/
            cursor: pointer;
            outline: none;
            transform: translateY(-50%);
            img {
                display: block;
                width: 12px;
            }
            /*&:hover {*/
            /*    opacity: 1;*/
            /*}*/
        }
        .banner-swiper-btn-prev {
            left: 14px;
        }
        .banner-swiper-btn-next {
            right: 14px;
        }
    }
</style>
