export const randomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export const sendWsData = (type, data) => {
    return JSON.stringify({
        type: type,
        data: {
            ...data,
            token: localStorage.getItem("sToken")
        }
    })
}
